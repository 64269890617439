import React from 'react';
import { Box, Flex, Link, Show, Text } from '@chakra-ui/react';
import { InformationBold, Close } from '../icons/Outline';
export interface InlineTextProps {
  type: string; // information, error, success
  content: string;
  onClose: () => void;
}

const InlineText: React.FC<InlineTextProps> = ({ type, content, onClose }) => {
  const getColorScheme = (type: string) => {
    switch (type) {
      case 'error': //red
        return {
          borderColor: '#D52F48',
          bgColor: '#D52F4829',
        };
      case 'success': //green
        return {
          borderColor: '#00795C',
          bgColor: '#00795C29',
        };
      case 'information': //blue
        return {
          borderColor: '#106BC7',
          bgColor: '#106BC729',
        };
      default: //default white
        return {
          borderColor: '#FFFFFF',
          bgColor: '#FFFFFF',
        };
    }
  };

  const { borderColor, bgColor } = getColorScheme(type);
  return (
    <Box borderLeftWidth="5px" borderLeftColor={borderColor} bg={bgColor}>
      <Flex gap="1">
        <Box ps="1rem" pt="1.1rem">
          <Show above="lg">
            <InformationBold />
          </Show>
        </Box>
        <Text fontWeight="400" pe="1rem" ps="0.5rem" py="1rem" dangerouslySetInnerHTML={{ __html: content }} />
        <Flex alignItems="center" px="1rem">
          <Link onClick={onClose}>
            <Close />
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

export default InlineText;
