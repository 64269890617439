import * as React from 'react';
import { SVGProps } from 'react';
const InformationBold = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1911_4819" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="20">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.2208 8.81922C11.2208 8.38857 11.5688 8.03636 12 8.03636C12.4312 8.03636 12.7792 8.3813 12.7792 8.81195V8.81922C12.7792 9.24935 12.4312 9.59844 12 9.59844C11.5688 9.59844 11.2208 9.24935 11.2208 8.81922ZM11.2208 11.5953C11.2208 11.1652 11.5688 10.8161 12 10.8161C12.4312 10.8161 12.7792 11.1652 12.7792 11.5953V15.7081C12.7792 16.1387 12.4312 16.4873 12 16.4873C11.5688 16.4873 11.2208 16.1387 11.2208 15.7081V11.5953ZM12 20.4416C7.34546 20.4416 3.55844 16.6551 3.55844 12C3.55844 7.34546 7.34546 3.55844 12 3.55844C16.6545 3.55844 20.4416 7.34546 20.4416 12C20.4416 16.6551 16.6545 20.4416 12 20.4416ZM12 2C6.48312 2 2 6.48623 2 12C2 17.5138 6.48312 22 12 22C17.5117 22 22 17.5138 22 12C22 6.48623 17.5117 2 12 2Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1911_4819)">
      <rect width="24" height="24" fill="#1F2827" />
    </g>
  </svg>
);
export default InformationBold;
