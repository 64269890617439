import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

//helpers and reducers
import { appConstants, cateringCTAs } from '../../../helper/client/constant';
import findAllItems from '../../../helper/findAllItems';
import { getApiUrl } from '../../../helper/getOtherAppURL';
import convertSpecialChars from '../../../helper/convertSpecialChars';
import { request } from '../../../helper/useAxios';
import { setShowError } from '../../../reducers/errorHandling';
import { deleteSR, saveSR } from '../../../pages/ServiceRequest/API/srApiService';
import { resetSRFormState, setFormFields } from '../../../reducers/requestDetailFormReducer';

//images
import alertImage from '../../../images/OrangeAlert.svg';

//chakra
import { Spinner } from '../../ServiceUI/Core/Spinner/Spinner';
import { SubContainer } from '../../ServiceUI/Core/Layout/SubContainer/SubContainer';
import Header from '../../ServiceUI/Core/Header/Header';

//bootstrap
import { Col, Container, Form, Image, Row, Button, Overlay, Tooltip } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';

//content
import content from '../../../pages/ServiceRequest/Content/CreateRequest.json';
import LocationDetailProps from '../CreateRequest/LocationDetails/__mocks__/default.json';
import PersonalDetailProps from '../CreateRequest/Personal-Detail/__mocks__/default.json';
import SecondaryContactCardProps from '../CreateRequest/SecondaryContactCard/__mocks__/default.json';

//components
import Location from '../CreateRequest/LocationDetails/Location';
import Other from '../CreateRequest/Other/Other';
import PersonalDetailsPrimary from '../CreateRequest/Personal-Detail/PersonalDetailPrimary';
import RequestDetail from '../CreateRequest/Request-Detail/RequestDetail';
import PersonalDetailsSecondary from '../CreateRequest/SecondaryContactCard/PersonalDetailsSecondary';
import './EditServiceRequestCreate.scss';

//modals
import DeleteModal from '../CreateRequest/DeleteModal';
import SuccessPopup from '../CreateRequest/SuccessPopup/SuccessPopup';
import UploadConfirmationSRModal from '../CreateRequest/UploadConfirmationSRModal';

const EditServiceRequestCreate = () => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const target = useRef(null);
  const apiUrl = getApiUrl;
  const reqDetailataFromReduxStore = useSelector((state) => {
    if (!!state) {
      return state;
    }
  });
  const uploadedFile = reqDetailataFromReduxStore.requestForm.uploaded;

  const [loading, setLoading] = useState({
    isLoading: true,
  });
  const [showSpinnerApiIsInProgress, setShowSpinnerApiIsInProgress] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);
  const [successMessage, setsuccessMessage] = useState('');
  const [reqDetailwrkTypeSubType, setreqDetailwrkTypeSubType] = useState({
    workType: '',
    workSubType: '',
    description: '',
  });
  const [secondaryContactformInvalid, setsecondaryContactformInvalid] = useState(false);
  const [showSecondary, setShowSecondary] = useState({
    isVisible: false,
  });

  const [extraFieldsForPageInstrction, setExtraFieldsForPageInstrction] = useState({
    data: [],
  });

  const [allDataFromAPI, setAllDataFromAPI] = useState({
    allFieldsinState: [],
  });
  const [requestInfo, setRequestInfo] = useState({
    commonFieldsArr: [],
  });
  const [locData, setLocData] = useState({
    location_data_state: [],
  });
  const [personalData, setPersonalData] = useState({
    personal_data_state: [],
    personal_data_secondary_state: [],
  });

  const [otherData, setOtherData] = useState({
    other_data_state: [],
    isPrimarySelected: null,
  });
  // TAdded these states to find out what are changes has been been and on which fields
  const [fieldIDstoUpdated, setfieldIDstoUpdated] = useState({
    fieldIds: [],
  });
  const [nextAssignmentIdState, setNextAssignmentId] = useState({
    nextAssignmentId: '',
  });
  const [caseId] = useState({
    caseId: id,
  });
  const [actionIdIdState, setActionId] = useState({
    actionId: '',
  });
  const [stateDropdown, setStateDropDown] = useState({
    dropDownArrayLocState: [],
    selectedValue: '',
    selectedDescription: '',
  });
  const [locationTypeDropdown, setLocationTypeDropdown] = useState({
    dropDownArrayLocType: [],
    selectedValue: '',
    selectedDescription: '',
  });
  const [vesselDropdown, setVesselDropdown] = useState({
    dropDownArrayVessel: [],
    selectedValue: '',
    selectedDescription: '',
  });

  const [entityDropdown, setEntityDropdown] = useState({
    dropDownArrayEntity: [],
    selectedValue: '',
    selectedDescription: '',
    selectedValueSecondary: '',
    selectedDescriptionSecondary: '',
  });
  const [baseNameDropDown, setbaseNameDropDown] = useState({
    dropDownArrayBaseName: [],
    selectedValue: '',
  });

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showSave, setSave] = useState(false);

  const [baseNameExists, setBaseNameExists] = useState(false);
  const [LocationAPIData, setLocationAPIData] = useState('');
  const [isDropdownSelected, setIsDropdownSelected] = useState(true);

  const requestTypeAndCaseID = window.location.search;
  const urlParams = new URLSearchParams(requestTypeAndCaseID);
  const type = urlParams.get('type');

  const [isMinorRequest] = useState(
    type === appConstants.SERVICE_REQUEST_TYPE_FULL ? false : type === appConstants.SERVICE_REQUEST_TYPE_MINOR && true,
  );

  useEffect(() => {
    isMinorRequest ? (document.title = 'New minor maintenance request') : (document.title = 'New service request');
  });

  const ViewReqInNewState = () => {
    setLoading((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });
    request(appConstants.API_CASES + caseId.caseId, 'get')
      .then((res) => {
        // updating the app states this we will use in update call
        setNextAssignmentId((prevState) => {
          return {
            ...prevState,
            nextAssignmentId: res.data.assignments[0].ID,
          };
        });
        setActionId((prevState) => {
          return {
            ...prevState,
            actionId: res.data.assignments[0].actions[0].ID,
          };
        });
        // This call is to prepopulate the req data
        const URL_CREATE_ACTION_API =
          appConstants.API_ASSIGNMENTS +
          res.data.assignments[0].ID +
          '/actions/' +
          res.data.assignments[0].actions[0].ID +
          '/?flatListOfFields=Basic';

        request(URL_CREATE_ACTION_API, 'get')
          .then((res) => {
            getDropDownsAndformatDataForUI(res.data);
          })
          .catch((error) => {
            dispatch(setShowError({ hasError: true, error }));
          });
      })
      .catch((error) => {
        dispatch(setShowError({ hasError: true, error }));
      });
  };

  const formatDataHandler = (original) => {
    const obj = {
      reference: original.reference,
      fieldID: original.fieldID,
      fieldName: original.reference.substr(original.reference.lastIndexOf('.') + 1),
      value: convertSpecialChars(original.value),
      readOnly: original.readOnly,
      required: original.required,
      error:
        original.required === true
          ? original.value.length > 0
            ? (original.reference === 'ServiceRequest.Location.Name' ||
                original.reference === 'ServiceRequest.Location.Address.FullAddress') &&
              baseNameExists
            : true
          : false,
      errorMessage:
        original.required === true
          ? original.value.length > 0
            ? (original.reference === 'ServiceRequest.Location.Name' ||
                original.reference === 'ServiceRequest.Location.Address.FullAddress') &&
              baseNameExists
              ? changeNameHandlerForValidation(original.reference, original.fieldID) + ' is required.'
              : ''
            : changeNameHandlerForValidation(original.reference, original.fieldID) + ' is required.'
          : '',
    };
    return { ...obj };
  };

  //function to check onload validation for SR primary section fields (Number)
  const validationCheckHandler = (field) => {
    let obj = field;
    //Number validation check
    if (field?.fieldID === 'Number') {
      if (field.value === '') {
        obj = {
          ...obj,
          error: true,
          errorMessage: changeNameHandlerForValidation(field.reference, field.id) + ' is required.',
        };
      } else if (!field.value.match('[0-9]{10,}')) {
        obj = {
          ...obj,
          error: true,
          errorMessage:
            'Please provide min 10 and max 15 digit ' + changeNameHandlerForValidation(field.reference, field.id),
        };
      } else if (field.value.length > appConstants.PHONE_NUM_MAX_LIMIT) {
        obj = {
          ...obj,
          error: true,
          errorMessage:
            'Please provide min 10 and max 15 digit ' + changeNameHandlerForValidation(field.reference, field.id),
        };
      } else {
        obj = { ...obj, error: false, errorMessage: '' };
      }
    }
    return { ...obj };
  };
  const getDropDownsArr = () => {
    return {
      stateDrodown: [...stateDropdown.dropDownArrayLocState],
      locationTypedropdown: [...locationTypeDropdown.dropDownArrayLocType],
      entityDropdown: [...entityDropdown.dropDownArrayEntity],
      vesselDropdown: [...vesselDropdown.dropDownArrayVessel],
    };
  };
  const setInitialFieldsforExtraItems = (allAPIData) => {
    const formatedData = allAPIData.map((field) => {
      if (!field?.reference) {
        return {};
      }
      return formatDataHandler(field);
    });

    const allExtra = formatedData.filter((item) => {
      return (
        item.fieldID === 'FID' ||
        item.fieldID === 'WorkCenterID' ||
        item.fieldID === 'EBI' ||
        item.fieldID === 'EBIBuilding' ||
        item.fieldID === 'Region' //this one is for initial load
      );
    });

    setExtraFieldsForPageInstrction((prevState) => {
      return {
        ...prevState,
        data: [...allExtra],
      };
    });
  };
  const setOtherFieldsForUI = (data) => {
    return new Promise((resolve, reject) => {
      // TODO: need to remove thi filter as this is just for demo
      let allAPIData = data.view.groups.map((obj) => {
        if (obj?.field?.reference) {
          return obj.field;
        } else {
          return obj.groups.map((objInner) => objInner?.field);
        }
      });
      allAPIData = allAPIData.flat();
      allAPIData = allAPIData.filter((obj) => obj !== undefined && obj);

      //Set required to true for address and defence location(name)
      const updatedallAPIData = allAPIData.map((item) => {
        if (
          item.reference === 'ServiceRequest.Location.Name' ||
          item.reference === 'ServiceRequest.Location.Address.FullAddress'
        ) {
          return { ...item, required: true };
        }
        return item;
      });

      const allIntialData = [...updatedallAPIData];

      setAllDataFromAPI((prevState) => {
        const refinedField = allIntialData.map((field) => {
          if (!field?.reference) {
            return {};
          }
          return formatDataHandler(field);
        });
        return {
          ...prevState,
          allFieldsinState: [...refinedField],
        };
      });
      setInitialFieldsforExtraItems(allAPIData);
      const initialSelectedLocationType = allIntialData.filter((item) =>
        item?.reference?.includes('ServiceRequest.Location.Type'),
      )[0].value;
      const initialFidIDFromPega = allIntialData.find((item) => item.fieldID === 'FID').value;
      let allFields;
      if (initialSelectedLocationType !== 'Other') {
        allFields = allIntialData.filter((item) => {
          return (
            item.fieldID !== 'Line1' &&
            item.fieldID !== 'City' &&
            item.fieldID !== 'Postcode' &&
            item.fieldID !== 'Description' &&
            item.fieldID !== 'Region' &&
            item.fieldID !== 'FID' &&
            item.fieldID !== 'WorkCenterID' &&
            item.fieldID !== 'EBI' &&
            item.fieldID !== 'EBIBuilding'
          );
        });
      } else {
        allFields = allIntialData.filter((item) => {
          return (
            item.fieldID !== 'Name' &&
            item.fieldID !== 'Region' &&
            item.fieldID !== 'Description' &&
            item.fieldID !== 'FullAddress' &&
            item.fieldID !== 'FID' &&
            item.fieldID !== 'WorkCenterID' &&
            item.fieldID !== 'EBI' &&
            item.fieldID !== 'EBIBuilding' &&
            item.fieldID !== 'SiteOrWharf'
          );
        });
      }
      const allPersonalDetailsFields = allFields.filter((fields) =>
        fields.reference.includes('ServiceRequest.PrimaryContact'),
      );
      const hasSecondaryFields = allFields.filter((fields) =>
        fields.reference.includes('ServiceRequest.HasSecondaryContact'),
      );
      const allPersonalSecondaryContactDetailsFields = allFields.filter((fields) =>
        fields.reference.includes('ServiceRequest.Contacts(1)'),
      );
      const savedWorkTypeAndSubType = allFields.filter(
        (fields) => fields.fieldID === 'WorkType' || fields.fieldID === 'WorkSubType',
      );
      const reqDetailDescription = allFields.filter((fields) => fields.fieldID === 'pyDescription');
      const restFields = allFields.filter(
        (fields) =>
          !fields.reference.includes('ServiceRequest.PrimaryContact') &&
          !fields.reference.includes('ServiceRequest.Contacts(1)') &&
          !fields.reference.includes('ServiceRequest.Location') &&
          !fields.reference.includes('ServiceRequest.CaseType') &&
          !fields.reference.includes('ServiceRequest.HasSecondaryContact'),
      );

      const refinedrestFields = restFields.map((field) => formatDataHandler(field));

      const allLocationDetailsFields = allFields.filter((fields) =>
        fields.reference.includes('ServiceRequest.Location'),
      );

      const refinedPersonalDetailsFields = allPersonalDetailsFields.map((field) => formatDataHandler(field));
      const personalDetailsValidationCheck = refinedPersonalDetailsFields.map((field) => validationCheckHandler(field));

      const refinedLocationDetailsFields = allLocationDetailsFields.map((field) => formatDataHandler(field));
      const refinedPersonalSecondaryContactFields = allPersonalSecondaryContactDetailsFields.map((field) =>
        formatDataHandler(field),
      );

      const finalObj = {
        locationData: [...refinedLocationDetailsFields],
        personalData: [...personalDetailsValidationCheck],
        other: [...refinedrestFields],
        seondaryContactData: [...refinedPersonalSecondaryContactFields],
      };
      const OrigInitialSelectedValState = finalObj.locationData.filter((item) => item.fieldID === 'State')[0].value;
      const initialSelectedValState = convertSpecialChars(OrigInitialSelectedValState);

      const initialSelectedValLocType = finalObj.locationData.filter((item) => item.fieldID === 'Type')[0].value;
      let initialSelectedValBaseName = '';
      if (initialSelectedValLocType !== 'Other') {
        const OrigInitialSelectedVesselName = finalObj.locationData.filter((item) => item.fieldID === 'SiteOrWharf')[0]
          ?.value;
        const initialSelectedVesselName = convertSpecialChars(OrigInitialSelectedVesselName);

        setVesselDropdown((prevState) => {
          const selectedVesselDescription =
            initialSelectedVesselName !== ''
              ? prevState.dropDownArrayVessel.filter((item) => item.code === initialSelectedVesselName)[0].description
              : '';

          return {
            ...prevState,

            selectedValue: initialSelectedVesselName,
            selectedDescription: selectedVesselDescription,
          };
        });
        const OrigInitialSelectedValBaseName = finalObj.locationData.filter((item) => item.fieldID === 'Name')[0]
          ?.value;

        initialSelectedValBaseName = convertSpecialChars(OrigInitialSelectedValBaseName);
        setbaseNameDropDown((prevState) => {
          return {
            ...prevState,
            selectedValue: initialSelectedValBaseName,
          };
        });
      }

      const OrigInitialSelectedValEntityName = finalObj.personalData.filter((item) => item.fieldID === 'Service')[0]
        .value;
      const initialSelectedValEntityName = convertSpecialChars(OrigInitialSelectedValEntityName);
      const OrigInitialSelectedValEntityNameForSecondaryContact = finalObj.seondaryContactData.filter(
        (item) => item.fieldID === 'Service',
      )[0]?.value;

      const initialSelectedValEntityNameForSecondaryContact = convertSpecialChars(
        OrigInitialSelectedValEntityNameForSecondaryContact,
      );
      const initialSelectedValForHasSeconadaryContact = hasSecondaryFields[0]?.value;

      setStateDropDown((prevState) => {
        const selectedStateDescription = prevState.dropDownArrayLocState.filter(
          (item) => item.code === initialSelectedValState,
        );

        return {
          ...prevState,
          selectedValue: initialSelectedValState,
          selectedDescription: !!selectedStateDescription ? selectedStateDescription[0].description : '',
        };
      });
      setShowSecondary((prevState) => {
        return {
          isVisible: initialSelectedValForHasSeconadaryContact === 'true',
        };
      });
      setreqDetailwrkTypeSubType((prevState) => {
        return {
          ...prevState,
          workType: savedWorkTypeAndSubType[0].value,
          workSubType: savedWorkTypeAndSubType[1].value,
          description: reqDetailDescription[0].value,
        };
      });

      setLocationTypeDropdown((prevState) => {
        const selectedLocTypeDescription = prevState.dropDownArrayLocType.filter(
          (item) => item.code === initialSelectedValLocType,
        );

        return {
          ...prevState,
          selectedValue: initialSelectedValLocType,
          selectedDescription: !!selectedLocTypeDescription ? selectedLocTypeDescription[0].description : '', // Type: is a field id for location Type
        };
      });
      setEntityDropdown((prevState) => {
        const selectedEntityDescription = prevState.dropDownArrayEntity.filter(
          (item) => item.code === initialSelectedValEntityName,
        );
        const selectedEntityDescriptionSecondary = prevState.dropDownArrayEntity.filter(
          (item) => item.code === initialSelectedValEntityNameForSecondaryContact,
        );

        return {
          ...prevState,
          selectedValue: !!initialSelectedValEntityName ? selectedEntityDescription[0].code : '',
          selectedDescription: !!initialSelectedValEntityName ? selectedEntityDescription[0].description : '', // Type: is a field id for Entity
          selectedValueSecondary: !!initialSelectedValEntityNameForSecondaryContact
            ? selectedEntityDescriptionSecondary[0].code
            : '',
          selectedDescriptionSecondary: !!initialSelectedValEntityNameForSecondaryContact
            ? selectedEntityDescriptionSecondary[0].description
            : '',
        };
      });

      setLocData((prevState) => {
        return {
          ...prevState,
          location_data_state: [...finalObj.locationData],
        };
      });
      setPersonalData((prevState) => {
        return {
          ...prevState,
          personal_data_state: [...finalObj.personalData],
          personal_data_secondary_state: [...finalObj.seondaryContactData],
        };
      });
      setOtherData((prevState) => {
        return {
          ...prevState,
          other_data_state: [...finalObj.other],
          isPrimarySelected: getIsPrimarySelected(finalObj.other),
        };
      });

      const allLoactionDropDowns = { ...getDropDownsArr() };

      finalObj.dropdowns = allLoactionDropDowns;

      resolve([
        finalObj,
        initialSelectedValState,
        initialSelectedValLocType,
        initialFidIDFromPega,
        initialSelectedValEntityName,
        initialSelectedValEntityNameForSecondaryContact,
      ]);
    });
  };
  const getIsPrimarySelected = (state) => {
    const flagforSelectedPrimary = state.filter((item) => item.fieldID === 'OperatorIsPrimary')[0].value;

    return flagforSelectedPrimary;
  };

  useEffect(() => {
    //iterate through formDataHandler and check every field from data
    if (LocationAPIData !== '') {
      setLocData((prevState) => {
        const updatedLocationData = LocationAPIData?.map((field) => formatDataHandler(field));
        return {
          ...prevState,
          location_data_state: [...updatedLocationData],
        };
      });
    }
  }, [baseNameExists]);

  const getBaseNameDropDownURLDEV = (state, location) => {
    let URL = appConstants.API_LOCATION_DETAILS;
    if (state !== '' && location !== '') {
      URL = URL + '?state=' + state + '&locationtype=' + location;
    } else if (state !== '' && location === '') {
      URL = URL + '?state=' + state;
    } else if (state === '' && location !== '') {
      URL = URL + '?locationtype=' + location;
    }

    return URL;
  };

  const getBaseNameDropDown = (
    state,
    locType,
    initialFidFromPega = 'notRequired', // this field will come only for intial load to prepopulate the base name according to FID
  ) => {
    let URL = '';
    URL = getBaseNameDropDownURLDEV(state, locType);

    const URL_baseName = apiUrl + URL;
    axios
      .get(URL_baseName, {
        withCredentials: true,
      })
      .then((res) => {
        setbaseNameDropDown((prevState) => {
          let initialSelectedValBaseNameObj = prevState.selectedValue;
          if (initialFidFromPega !== 'notRequired' && initialFidFromPega.length > 0) {
            let foundItem = res?.data?.locDetails.find((item) => item.fId === initialFidFromPega);
            initialSelectedValBaseNameObj = foundItem?.name ?? null;
            setBaseNameExists(initialSelectedValBaseNameObj === null ? true : false);
          }
          return {
            ...prevState,
            selectedValue: initialSelectedValBaseNameObj,
            dropDownArrayBaseName: [...(res?.data?.locDetails ?? [])],
          };
        });

        setLoading((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      })
      .catch((error) => {
        dispatch(setShowError({ hasError: true, error }));
      });
  };
  const getDiffDropDown = (value) => {
    let URL = appConstants.API_REF_DATA + value;
    URL = apiUrl + URL;
    const apiRes = axios.get(URL, {
      withCredentials: true,
    });
    return apiRes;
  };

  const getGroupDropDown = () => {
    let URL = appConstants.API_REF_DATA_GROUP;
    URL = apiUrl + URL;
    const apiRes = axios.get(URL, {
      withCredentials: true,
    });
    return apiRes;
  };

  // This method is created to format the res according to the UI component display.
  const getDropDownsAndformatDataForUI = (data) => {
    getDiffDropDown('State').then((result) => {
      const refValuesWithSelectLocState = [{ code: '', description: 'Select' }].concat(result.data.refValues);
      setStateDropDown((prevState) => {
        return {
          ...prevState,
          dropDownArrayLocState: refValuesWithSelectLocState,
        };
      });
      getDiffDropDown('Location').then((result) => {
        setLocationTypeDropdown((prevState) => {
          return {
            ...prevState,
            dropDownArrayLocType: result.data.refValues,
          };
        });
        getDiffDropDown('Vessel').then((result) => {
          const refValuesWithSelectVessel = [{ code: '', description: 'Select' }].concat(result.data.refValues);
          setVesselDropdown((prevState) => {
            return {
              ...prevState,
              dropDownArrayVessel: refValuesWithSelectVessel,
            };
          });
          getGroupDropDown()
            .then((result) => {
              const refValuesWithSelectEntityPersonalData = [{ code: '', description: 'Select' }].concat(
                result.data.groupDetails,
              );
              setEntityDropdown((prevState) => {
                return {
                  ...prevState,
                  dropDownArrayEntity: refValuesWithSelectEntityPersonalData,
                };
              });

              return new Promise((resolve, reject) => {
                resolve('All Done');
              });
            })
            .then((result) => {
              const apiData = { ...data };
              const promise = setOtherFieldsForUI(apiData);
              promise.then((val) => {
                setLocationAPIData(val[0].locationData);
                getBaseNameDropDown(val[1], val[2], val[3]);
              });
            })
            .catch((error) => {
              dispatch(setShowError({ hasError: true, error }));
            });
        });
      });
    });
  };

  const insertFieldIdsIfNotAlreadyPresent = (existingArr, item) => {
    if (existingArr.indexOf(item) === -1) {
      existingArr.push(item);
    }
    return existingArr;
  };

  const updateDefenceLocationState = (value, passedState, id) => {
    const newState = passedState.map((obj) => {
      let finalObj = obj;
      if (obj.fieldID === id) {
        finalObj = {
          ...finalObj,
          error: value.length === 0,
          value,
          errorMessage:
            value.length === 0 ? changeNameHandlerForValidation(obj.reference, obj.fieldID) + ' is required.' : '',
        };
      }
      //Check impacted area validation when new defence location is selected
      if (obj.fieldID === 'Area') {
        finalObj = {
          ...finalObj,
          error: obj.value.length === 0,
          value: obj.value,
          errorMessage:
            obj.value.length === 0 ? changeNameHandlerForValidation(obj.reference, obj.fieldID) + ' is required.' : '',
        };
      }
      return finalObj;
    });

    return newState;
  };

  const updateStateValue = (id, name, value, passedState, reference) => {
    const selectedWorkType = requestInfo.commonFieldsArr.find((item) => item.fieldID === 'WorkType')?.value;

    const newState = passedState.map((obj) => {
      let finalObj = obj;
      if (obj.fieldID === id && id !== 'PreferredContactTimes') {
        if (id !== 'EmployeeID' && id !== 'Area' && value === '') {
          finalObj = {
            ...finalObj,
            error: true,
            value,
            errorMessage: changeNameHandlerForValidation(reference, id) + ' is required.',
          };
        } else if (id === 'Number') {
          if (!value.match('[0-9]{10,}')) {
            finalObj = {
              ...finalObj,
              error: true,
              value,
              errorMessage: 'Please provide min 10 and max 15 digit ' + changeNameHandlerForValidation(reference, id),
            };
          } else if (value.length > appConstants.PHONE_NUM_MAX_LIMIT) {
            finalObj = {
              ...finalObj,
              error: true,
              value,
              errorMessage: 'Please provide min 10 and max 15 digit ' + changeNameHandlerForValidation(reference, id),
            };
          } else {
            finalObj = { ...finalObj, error: false, value, errorMessage: '' };
          }
        } else if (id === 'Address') {
          const emailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
          if (!emailRegEx.test(value)) {
            finalObj = {
              ...finalObj,
              error: true,
              value,
              errorMessage: 'Please provide a valid ' + changeNameHandlerForValidation(reference, id),
            };
          } else {
            finalObj = { ...finalObj, error: false, value, errorMessage: '' };
          }
        }
        // test
        else if (id === 'Area') {
          if (
            selectedWorkType === appConstants.SR_TYPE_CATERING ||
            selectedWorkType === appConstants.SR_TYPE_TRANSPORT
          ) {
            finalObj = { ...finalObj, error: false, value, errorMessage: '' };
          }
          if (
            selectedWorkType !== appConstants.SR_TYPE_CATERING &&
            selectedWorkType !== appConstants.SR_TYPE_TRANSPORT
          ) {
            finalObj = {
              ...finalObj,
              error: value.length === 0,
              value,
              errorMessage:
                value.length === 0 ? changeNameHandlerForValidation(obj.reference, obj.fieldID) + ' is required.' : '',
            };
          }
        }
        //test
        else if (id === 'EmployeeID') {
          if (value.length > 9) {
            finalObj = {
              ...finalObj,
              error: true,
              value,
              errorMessage:
                changeNameHandlerForValidation(reference, id) + ' should not exceeds more than 9 characters',
            };
          } else if (value.length > 0 && value.length < 6) {
            finalObj = {
              ...finalObj,
              error: true,
              value,
              errorMessage: changeNameHandlerForValidation(reference, id) + ' should be more than 6 characters.',
            };
          } else if (value.length > 0 && value.length < 9) {
            const specialChars = appConstants.ATTACHMENTS_SPECIAL_CHARACTERS_STRING;

            specialChars.split('').forEach((specialChar) => {
              if (value.includes(specialChar)) {
                finalObj = {
                  ...finalObj,
                  error: true,
                  value,
                  errorMessage: 'Please enter a valid ' + changeNameHandlerForValidation(reference, id),
                };
              } else {
                finalObj = {
                  ...finalObj,
                  error: false,
                  value,
                  errorMessage: '',
                };
              }
            });
          } else {
            finalObj = { ...finalObj, error: false, value, errorMessage: '' };
          }
        } else {
          finalObj = { ...finalObj, error: false, value, errorMessage: '' };
        }
      } else {
        if (obj.fieldID === id && id === 'PreferredContactTimes') {
          finalObj = {
            ...finalObj,
            error: false,
            value,
            errorMessage: '',
          };
        }
      }
      return finalObj;
    });

    return newState;
  };
  const updateErrorFlagifWorkTypeChanged = (workType) => {
    const newLocStateArr = locData.location_data_state.map((obj) => {
      let finalObj = obj;
      if (
        obj.fieldID === 'Area' &&
        (workType === appConstants.SR_TYPE_CATERING || workType === appConstants.SR_TYPE_TRANSPORT)
      ) {
        finalObj = { ...finalObj, error: false, errorMessage: '' };
      }
      if (
        obj.fieldID === 'Area' &&
        workType !== appConstants.SR_TYPE_CATERING &&
        workType !== appConstants.SR_TYPE_TRANSPORT &&
        obj.value.length === 0
      ) {
        finalObj = {
          ...finalObj,
          error: true,
          errorMessage: changeNameHandlerForValidation(obj.reference, obj.fieldID) + ' is required.',
        };
      }
      return finalObj;
    });
    return newLocStateArr;
  };
  const handleRequestDetailsUpdateForCommonFields = (commonFields) => {
    commonFields.forEach((item) => {
      if (item.fieldID === 'WorkType') {
        setLocData((prevState) => {
          const updatedLocationState = updateErrorFlagifWorkTypeChanged(item.value);

          return {
            ...prevState,
            location_data_state: [...updatedLocationState],
          };
        });
      }
    });
    setRequestInfo((prevState) => {
      return {
        ...prevState,
        commonFieldsArr: [...commonFields],
      };
    });
  };

  const handleDefenceLocationText = (text, fieldID) => {
    setLocData((prevState) => {
      const updatedLocationState = updateDefenceLocationState(text, locData.location_data_state, fieldID);
      return {
        ...prevState,
        location_data_state: [...updatedLocationState],
      };
    });
  };

  const handleLocationDataUpdate = (id, name, value, reference) => {
    setLocData((prevState) => {
      const updatedLocationState = updateStateValue(id, name, value, locData.location_data_state, reference);

      return {
        ...prevState,
        location_data_state: [...updatedLocationState],
      };
    });
  };
  const updatelocationFieldsAterBaseNameChanged = (selectedObj) => {
    const newArray = [...locData.location_data_state];
    const objIndexState = newArray.findIndex((obj) => obj.fieldID === 'State');

    newArray[objIndexState].value = selectedObj.state;
    newArray[objIndexState].error = selectedObj.state.length > 0 ? false : true;
    setStateDropDown((prevState) => {
      const selectedStateDescription = prevState.dropDownArrayLocState.filter(
        (item) => item.code === selectedObj.state,
      );

      return {
        ...prevState,
        selectedValue: selectedObj.state,
        selectedDescription: !!selectedStateDescription ? selectedStateDescription[0].description : '',
      };
    });

    // update location Type
    const objIndexLocationType = newArray.findIndex((obj) => obj.fieldID === 'Type');

    newArray[objIndexLocationType].value = selectedObj.locationType;
    newArray[objIndexLocationType].error = selectedObj.locationType.length > 0 ? false : true;
    setLocationTypeDropdown((prevState) => {
      const selectedLocTypeDescription = prevState.dropDownArrayLocType.filter(
        (item) => item.code === selectedObj.locationType,
      );

      return {
        ...prevState,
        selectedValue: selectedObj.locationType,
        selectedDescription: !!selectedLocTypeDescription ? selectedLocTypeDescription[0].description : '', // Type: is a field id for location Type
      };
    });
    // update Base Name

    const objIndexBaseName = newArray.findIndex((obj) => obj.fieldID === 'Name');

    newArray[objIndexBaseName].value = selectedObj.name;
    newArray[objIndexBaseName].error = selectedObj.name.length > 0 ? false : true;
    // update baseAddress
    const objIndexBaseAddress = newArray.findIndex((obj) => obj.fieldID === 'FullAddress');

    newArray[objIndexBaseAddress].value = selectedObj.baseAddress;
    newArray[objIndexBaseAddress].error = selectedObj.baseAddress.length > 0 ? false : true;
    // update Area

    const objIndexArea = newArray.findIndex((obj) => obj.fieldID === 'Area');

    newArray[objIndexArea].value = selectedObj.area;
    const objIndexSiteOrWharf = newArray.findIndex((obj) => obj.fieldID === 'SiteOrWharf');

    newArray[objIndexSiteOrWharf].value = selectedObj.siteOrWharf;

    return newArray;
  };
  const clearFields = (index, array) => {
    if (index !== -1) {
      array[index].value = '';
    }
  };
  const updatebaseAddressDetailsOnchangeOfStateOrLocation = (DropdownName, value) => {
    setbaseNameDropDown((prevState) => {
      return {
        ...prevState,
        selectedValue: '',
      };
    });
    let newLocationArray = [];
    if (DropdownName === 'Location' && value === 'Other') {
      // need to set extra fields as blank except region
      setExtraFieldsForPageInstrction((prevState) => {
        const updatedExtraFieldArr = prevState.data.map((item) => {
          if (item.fieldID === 'FID') {
            return { ...item, value: '' };
          } else if (item.fieldID === 'WorkCenterID') {
            return { ...item, value: '' };
          } else if (item.fieldID === 'EBI') {
            return { ...item, value: '' };
          } else if (item.fieldID === 'EBIBuilding') {
            return { ...item, value: '' };
          } else {
            return { ...item };
          }
        });

        return {
          ...prevState,
          data: [...updatedExtraFieldArr],
        };
      });
      // ending
      const baseArray = [...locData.location_data_state];

      const p = baseArray.filter((item) => {
        return item.fieldID !== 'FullAddress' && item.fieldID !== 'Name' && item.fieldID !== 'SiteOrWharf';
      });

      const allDatafromOrig = [...allDataFromAPI.allFieldsinState];
      const otherRelatedExtraFields = allDatafromOrig.filter(
        (item) => item.fieldID === 'Line1' || item.fieldID === 'City' || item.fieldID === 'Postcode',
      );
      const areaField = p.filter((item) => item.fieldID === 'Area');

      const dropDownField = p.filter((item) => item.fieldID === 'State' || item.fieldID === 'Type');
      // setting the location type as other
      const objIndex = dropDownField.findIndex((obj) => obj.fieldID === 'Type');
      if (value === '') {
        dropDownField[objIndex].error = true;
        dropDownField[objIndex].errorMessage =
          changeNameHandlerForValidation(dropDownField[objIndex].reference, dropDownField[objIndex].fieldID) +
          ' is required.';
      } else {
        dropDownField[objIndex].error = false;
        dropDownField[objIndex].errorMessage = '';
      }

      dropDownField[objIndex].value = value;

      newLocationArray = [...dropDownField, ...otherRelatedExtraFields, ...areaField];

      setbaseNameDropDown((prevState) => {
        return {
          ...prevState,
          selectedValue: '',
        };
      });
      setfieldIDstoUpdated((prevState) => {
        const updatedIds = prevState.fieldIds.filter((item) => {
          return item !== 'Name' && item !== 'FullAddress' && item !== 'SiteOrWharf';
        });

        ['State', 'Type'].forEach((item) => {
          updatedIds.indexOf(item) === -1 ? updatedIds.push(item) : console.log('This item already exists');
        });

        return {
          ...prevState,
          fieldIds: updatedIds,
        };
      });
    } else if (DropdownName === 'Location' && (value === 'Base' || value === '')) {
      const allorigData = [...allDataFromAPI.allFieldsinState];
      const filteredLocationArray = allorigData.filter((item) => {
        return (
          item.fieldID === 'State' ||
          item.fieldID === 'Type' ||
          item.fieldID === 'Name' ||
          item.fieldID === 'FullAddress' ||
          item.fieldID === 'Area' ||
          item.fieldID === 'SiteOrWharf'
        );
      });
      const selectedLoacationStateArr = locData.location_data_state.find((item) => item.fieldID === 'State');

      newLocationArray = filteredLocationArray.map((item) => {
        if (item.fieldID === 'Type' && value === '') {
          return {
            ...item,
            error: true,
            value: '',
            errorMessage: changeNameHandlerForValidation(item.reference, item.fieldID) + ' is required.',
          };
        } else if (item.fieldID === 'Type' && value !== '') {
          return {
            ...item,
            error: false,
            errorMessage: '',
          };
        } else if (item.fieldID === 'State' && selectedLoacationStateArr.error) {
          return {
            ...item,
            error: true,
            errorMessage: changeNameHandlerForValidation(item.reference, item.fieldID) + ' is required.',
          };
        } else {
          return item;
        }
      });
    } else {
      newLocationArray = locData.location_data_state.map((obj) => {
        let finalObj = obj;
        if (obj.fieldID === DropdownName) {
          if (value === '') {
            finalObj = {
              ...finalObj,
              error: true,
              errorMessage: changeNameHandlerForValidation(obj.reference, obj.fieldID) + ' is required.',
            };
          } else {
            finalObj = { ...finalObj, error: false, value, errorMessage: '' };
          }
        }
        return finalObj;
      });
    }

    //  update full Address TODO: check we really want to reset this field!!???
    const objIndexFullAddress = newLocationArray.findIndex((obj) => obj.fieldID === 'FullAddress');
    if (objIndexFullAddress !== -1) {
      newLocationArray[objIndexFullAddress].error = true;
      newLocationArray[objIndexFullAddress].errorMessage =
        changeNameHandlerForValidation(
          newLocationArray[objIndexFullAddress].reference,
          newLocationArray[objIndexFullAddress].fieldID,
        ) + ' is required.';
    }
    const objIndexBase = newLocationArray.findIndex((obj) => obj.fieldID === 'Name');
    if (objIndexBase !== -1) {
      newLocationArray[objIndexBase].error = true;
      newLocationArray[objIndexBase].errorMessage =
        changeNameHandlerForValidation(
          newLocationArray[objIndexBase].reference,
          newLocationArray[objIndexBase].fieldID,
        ) + ' is required.';
    }

    clearFields(objIndexFullAddress, newLocationArray);
    const objIndexSiteOrWharf = newLocationArray.findIndex((obj) => obj.fieldID === 'SiteOrWharf');
    clearFields(objIndexSiteOrWharf, newLocationArray);
    const objIndexAddressLine1 = newLocationArray.findIndex((obj) => obj.fieldID === 'Line1');
    if (objIndexAddressLine1 !== -1) {
      newLocationArray[objIndexAddressLine1].error = true;
      newLocationArray[objIndexAddressLine1].errorMessage =
        changeNameHandlerForValidation(
          newLocationArray[objIndexAddressLine1].reference,
          newLocationArray[objIndexAddressLine1].fieldID,
        ) + ' is required.';
    }
    clearFields(objIndexAddressLine1, newLocationArray);
    const objIndexCity = newLocationArray.findIndex((obj) => obj.fieldID === 'City');
    if (objIndexCity !== -1) {
      newLocationArray[objIndexCity].error = true;
      newLocationArray[objIndexCity].errorMessage =
        changeNameHandlerForValidation(
          newLocationArray[objIndexCity].reference,
          newLocationArray[objIndexCity].fieldID,
        ) + ' is required.';
    }
    clearFields(objIndexCity, newLocationArray);
    const objIndexPostCode = newLocationArray.findIndex((obj) => obj.fieldID === 'Postcode');
    if (objIndexPostCode !== -1) {
      newLocationArray[objIndexPostCode].error = true;
      newLocationArray[objIndexPostCode].errorMessage =
        changeNameHandlerForValidation(
          newLocationArray[objIndexPostCode].reference,
          newLocationArray[objIndexPostCode].fieldID,
        ) + ' is required.';
    }
    clearFields(objIndexPostCode, newLocationArray);
    const objIndexDescription = newLocationArray.findIndex((obj) => obj.fieldID === 'Description');
    if (objIndexDescription !== -1) {
      newLocationArray[objIndexDescription].error = true;
      newLocationArray[objIndexDescription].errorMessage =
        changeNameHandlerForValidation(
          newLocationArray[objIndexDescription].reference,
          newLocationArray[objIndexDescription].fieldID,
        ) + ' is required.';
    }
    clearFields(objIndexDescription, newLocationArray);
    const objIndexArea = newLocationArray.findIndex((obj) => obj.fieldID === 'Area');
    if (objIndexArea !== -1) {
      newLocationArray[objIndexArea].error = true;
      newLocationArray[objIndexArea].errorMessage =
        changeNameHandlerForValidation(
          newLocationArray[objIndexArea].reference,
          newLocationArray[objIndexArea].fieldID,
        ) + ' is required.';
    }
    clearFields(objIndexArea, newLocationArray);

    return newLocationArray;
  };
  const handleBaseNameDropdownUpdate = (selectedBaseName, selectedBaseNameObj, fieldId) => {
    setExtraFieldsForPageInstrction((prevState) => {
      const updatedExtraFieldArr = prevState.data.map((item) => {
        if (item.fieldID === 'FID') {
          return { ...item, value: selectedBaseNameObj.fId };
        } else if (item.fieldID === 'WorkCenterID') {
          return { ...item, value: selectedBaseNameObj.workCenterID };
        } else if (item.fieldID === 'EBI') {
          return { ...item, value: selectedBaseNameObj.ebi };
        } else if (item.fieldID === 'EBIBuilding') {
          return { ...item, value: selectedBaseNameObj.ebiBuilding };
        } else if (item.fieldID === 'Region') {
          return { ...item, value: selectedBaseNameObj.region };
        } else {
          return { ...item };
        }
      });

      return {
        ...prevState,
        data: [...updatedExtraFieldArr],
      };
    });

    setbaseNameDropDown((prevState) => {
      return {
        ...prevState,
        selectedValue: selectedBaseName,
      };
    });
    // update location_data_state region,locationtype,state,baseaddress,area,addSiteWharf if not blank
    setLocData((prevState) => {
      return {
        ...prevState,
        location_data_state: updatelocationFieldsAterBaseNameChanged(selectedBaseNameObj),
      };
    });

    const fieldsToUpdateonBaseNameChange =
      selectedBaseNameObj.siteOrWharf.length > 0
        ? ['State', 'Type', 'Name', 'FullAddress', 'Area', 'SiteOrWharf']
        : ['State', 'Type', 'Name', 'FullAddress', 'Area'];

    fieldsToUpdateonBaseNameChange.forEach((id) => {
      setfieldIDstoUpdated((prevState) => {
        const updatedIds = prevState.fieldIds.filter((item) => {
          return item !== 'Line1' && item !== 'City' && item !== 'Postcode' && item !== 'Description';
        });

        return {
          ...prevState,
          fieldIds: insertFieldIdsIfNotAlreadyPresent(updatedIds, id),
        };
      });
    });

    // add logic here for defence location
    setLocData((prevState) => {
      const updatedLocationState = updateDefenceLocationState(selectedBaseName, locData.location_data_state, fieldId);
      return {
        ...prevState,
        location_data_state: [...updatedLocationState],
      };
    });
  };

  const handleVesselDropDownUpdate = (value, selectedCode, fieldId) => {
    setVesselDropdown((prevState) => {
      return {
        ...prevState,
        selectedValue: selectedCode,
        selectedDescription: value,
      };
    });
    setfieldIDstoUpdated((prevState) => {
      return {
        ...prevState,
        fieldIds: insertFieldIdsIfNotAlreadyPresent(prevState.fieldIds, fieldId),
      };
    });
  };
  const handleEntityDropdownUpdate = (value, selectedValueCode, fieldId) => {
    setEntityDropdown((prevState) => {
      return {
        ...prevState,
        selectedValue: selectedValueCode,
        selectedDescription: value,
      };
    });
  };
  const handleEntityDropdownUpdateSecondary = (value, selectedValueCode, fieldId) => {
    setEntityDropdown((prevState) => {
      return {
        ...prevState,

        selectedValueSecondary: selectedValueCode,
        selectedDescriptionSecondary: value,
      };
    });
  };

  const handleStateLocationTypeDropdownUpdate = (dropdownName, value, selectedValueCode, fieldId) => {
    const newlocFieldsDetails = updatebaseAddressDetailsOnchangeOfStateOrLocation(dropdownName, selectedValueCode);

    if (dropdownName === 'State') {
      setExtraFieldsForPageInstrction((prevState) => {
        const updatedExtraFieldArr = prevState.data.map((item) => {
          if (item.fieldID === 'Region') {
            return { ...item, value: selectedValueCode };
          } else {
            return { ...item };
          }
        });

        return {
          ...prevState,
          data: [...updatedExtraFieldArr],
        };
      });
      getBaseNameDropDown(selectedValueCode, locationTypeDropdown.selectedValue);
      setStateDropDown((prevState) => {
        return {
          ...prevState,
          selectedValue: selectedValueCode,
          selectedDescription: value,
        };
      });
    } else if (dropdownName === 'Location') {
      setVesselDropdown((prevState) => {
        return {
          ...prevState,
          selectedValue: '',
          selectedDescription: '',
        };
      });
      setLocationTypeDropdown((prevState) => {
        return {
          ...prevState,
          selectedValue: selectedValueCode,
          selectedDescription: selectedValueCode === 'Other' ? 'Other Location' : 'Defence Location',
        };
      });
      getBaseNameDropDown(stateDropdown.selectedValue, selectedValueCode);
    }
    setLocData((prevState) => {
      return {
        ...prevState,
        location_data_state: [...newlocFieldsDetails],
      };
    });
  };
  const seconadryContactdetailsAccordingoPegaInput = (param) => {
    const input = [...param];

    const updatedsecondaryContactDetails = input.map((item) => {
      return {
        ...item,
        reference: 'ServiceRequest.Contact(1).' + item.fieldID,
      };
    });

    setEntityDropdown((prevState) => {
      return {
        ...prevState,
        selectedValue: '',
        selectedDescription: 'Select', // Type: is a field id for Entity
        selectedValueSecondary: prevState.selectedValue,
        selectedDescriptionSecondary: prevState.selectedDescription,
      };
    });

    return [...updatedsecondaryContactDetails];
  };
  const primaryContactdetailsAccordingoPegaInput = (param) => {
    const input = [...param];

    const updatedPrimaryContactDetails = input.map((item) => {
      let finalObj = item;
      if (item.fieldID === 'PreferredContactTimes') {
        finalObj = { ...finalObj, error: false, errorMessage: '' };
      } else {
        finalObj = {
          ...finalObj,
          value: '',
          error: true,
          errorMessage: changeNameHandlerForValidation(item.reference, item.fieldID) + ' is required.',
        };
      }
      return finalObj;
    });

    return updatedPrimaryContactDetails;
  };

  const handleOtherDetailsUpdate = (id, name, value, reference) => {
    // value is true when Yes is selected for PC radio buttons
    if (value === 'true') {
      setPersonalData((prevState) => {
        const updatedPersonalData = prevState.personal_data_secondary_state.map((item) => {
          if (item.fieldID === 'Service' && (item.value === '' || item.value === 'Select')) {
            item = {
              ...item,
              value: '',
              error: true,
              errorMessage: changeNameHandlerForValidation(item.reference, item.fieldID) + ' is required.',
            };
          }
          //validation check for preferred contact number field
          //reset the Preferred contact number field to "" when Yes/No toggled
          if (item.fieldID === 'Number') {
            item = {
              ...item,
              value: '',
            };
          }

          if (item.fieldID === 'Number' && item.value === '') {
            item = {
              ...item,
              value: '',
              error: true,
              errorMessage: changeNameHandlerForValidation(item.reference, item.fieldID) + ' is required.',
            };
          }
          return {
            ...item,
            reference: item.reference.replace('Contacts(1)', 'PrimaryContact'),
          };
        });
        //Secondary Contact
        const updatedSecondaryData = prevState.personal_data_secondary_state.map((item) => {
          return {
            ...item,
            value: '',
          };
        });

        return {
          ...prevState,
          personal_data_secondary_state: [...updatedSecondaryData],
          personal_data_state: [...updatedPersonalData],
        };
      });

      setEntityDropdown((prevState) => {
        const origInitialSelectedValEntityNameinOther = personalData.personal_data_secondary_state.filter(
          (item) => item.fieldID === 'Service',
        )[0].value;
        const initialSelectedValEntityNameInOther = convertSpecialChars(origInitialSelectedValEntityNameinOther);
        const selectedEntityDescriptionInOther = prevState.dropDownArrayEntity.filter(
          (item) => item.code === initialSelectedValEntityNameInOther,
        );

        return {
          ...prevState,
          selectedValue: !!initialSelectedValEntityNameInOther ? selectedEntityDescriptionInOther[0].code : '',
          selectedDescription: !!initialSelectedValEntityNameInOther
            ? selectedEntityDescriptionInOther[0].description
            : '', // Type: is a field id for Entity
          selectedValueSecondary: '',
          selectedDescriptionSecondary: 'Select',
        };
      });
      setShowSecondary((prevState) => {
        return {
          isVisible: false,
        };
      });
    } else {
      setShowSecondary((prevState) => {
        return {
          isVisible: true,
        };
      });
      setPersonalData((prevState) => {
        const finalSecData = seconadryContactdetailsAccordingoPegaInput(prevState.personal_data_state);

        const finalPriData = primaryContactdetailsAccordingoPegaInput(prevState.personal_data_state);

        return {
          ...prevState,
          personal_data_state: finalPriData,
          personal_data_secondary_state: finalSecData,
        };
      });
    }
    setOtherData((prevState) => {
      //on change of radio buttons reset preferred contact number
      const updatedotherState = updateStateValue(id, name, value, otherData.other_data_state, reference);
      return {
        ...prevState,
        other_data_state: [...updatedotherState],
        isPrimarySelected: getIsPrimarySelected(updatedotherState),
      };
    });
  };

  const handlePersonalDetailsUpdate = (id, name, value, reference) => {
    setPersonalData((prevState) => {
      const updatedPersonalState = updateStateValue(id, name, value, prevState.personal_data_state, reference);

      return {
        ...prevState,
        personal_data_state: updatedPersonalState,
      };
    });
  };

  const handlePersonalSecondaryDetailsUpdate = (secondaryForm, secondaryFormError, updatedFormData) => {
    const selectedServiceInSecondary = updatedFormData.filter((item) => item.fieldID === 'Service')[0]?.value;
    // !!!dont change description to code
    const selectedcodeforService =
      selectedServiceInSecondary !== ''
        ? entityDropdown.dropDownArrayEntity.filter((item) => item.description === selectedServiceInSecondary)[0]?.code
        : '';
    const updatedArr = updatedFormData.map((item) => {
      if (item.fieldID === 'Service') {
        return { ...item, value: selectedcodeforService };
      } else {
        return item;
      }
    });
    setPersonalData((prevState) => {
      return {
        ...prevState,
        personal_data_secondary_state: [...updatedArr],
      };
    });
  };

  const preparePageInstructions = (updatedItemArr) => {
    const preparePageInstructionsArr = updatedItemArr
      .filter(
        (item) => item.fieldID !== cateringCTAs.addNewMeal.fieldID && item.fieldID !== cateringCTAs.removeMeal.fieldID,
      )
      .map((item) => {
        return {
          instruction: 'UPDATE',
          target: '.' + item.reference.replace('.' + item.fieldID, ''),
          content: {
            [item.fieldID]: item.value,
          },
        };
      });
    return preparePageInstructionsArr;
  };
  const updateFieldIdsForSecondaryContact = () => {
    personalData.personal_data_secondary_state.forEach((item) => {
      setfieldIDstoUpdated((prevState) => {
        return {
          ...prevState,
          fieldIds: insertFieldIdsIfNotAlreadyPresent(fieldIDstoUpdated.fieldIds, item.fieldID),
        };
      });
    });
  };

  const checkifItHasArray = (valueObj, array) => {
    if (Array.isArray(valueObj)) {
      valueObj.forEach((item) => {
        checkifItHasArray(item, array);
      });
    } else {
      array.push(valueObj);
    }
  };

  const getDataFromReduxStore = () => {
    const wholeObject = Object.entries(reqDetailataFromReduxStore.requestForm.formFields);

    let requestInformationData = [];

    for (const [key, value] of wholeObject) {
      checkifItHasArray(value, requestInformationData);
    }

    const filteringdataWhichHasReferenceField = requestInformationData.filter(
      (item) => !!item.reference && item.value !== undefined,
    );

    const reqDetailsALLFields = [
      ...requestInfo.commonFieldsArr,
      ...filteringdataWhichHasReferenceField, // changed this bcz while editing we migh need to send some value as blank
    ];

    return reqDetailsALLFields;
  };
  const getDataForSeconadaryForPageInstruction = () => {
    return [
      {
        fieldID: 'HasSecondaryContact',
        value: showSecondary.isVisible,
        reference: 'ServiceRequest.HasSecondaryContact',
      },
    ];
  };
  const isFormInvalid = () => {
    const isInvalid = locData.location_data_state.some((item) => item.error === true);
    const isInvalidPersonal = personalData.personal_data_state.some((item) => item.error === true);
    const reqDetailsFields = findAllItems(reqDetailataFromReduxStore?.requestForm.formFields, 'fieldID');
    const isInvalidDateValidation = reqDetailsFields?.some((item) => item.customError === true);
    const isInvalidReqDetails = reqDetailsFields?.some((item) => item.error);

    if (showSecondary.isVisible) {
      return (
        isInvalid ||
        isInvalidPersonal ||
        secondaryContactformInvalid ||
        isInvalidReqDetails ||
        isDropdownSelected ||
        isInvalidDateValidation
      );
    } else {
      return isInvalid || isInvalidPersonal || isInvalidReqDetails || isDropdownSelected || isInvalidDateValidation;
    }
  };

  //DELETE PEGA API
  const deleteSRHandler = async () => {
    setLoading((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });
    await deleteSR(caseId.caseId)
      .then((_) => {
        setLoading((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
        //dispatch(resetSRFormState());
        setShowDeleteModal(false);
        setSave(false);

        //Success delete message
        setshowSuccess(true);
        setsuccessMessage(appConstants.SERVICE_REQUEST_DELETE_SUCCESS_MESSAGE);
      })
      .catch((error) => {
        setLoading((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
        setShowSpinnerApiIsInProgress(false);
        dispatch(setShowError({ hasError: true, error }));
      });
  };

  const saveServiceRequestHandler = (flag) => {
    setShowSpinnerApiIsInProgress(true);
    // NOTE: Here we are sending all fields for pageinstruction of location and
    // personal details but for request details we will be sending only those which are updated by user
    const onlyOperatorIsPrimaryDetails = otherData.other_data_state.filter(
      (item) => item.fieldID === 'OperatorIsPrimary',
    );
    const hasSecondarySelected = getDataForSeconadaryForPageInstruction();
    const requestDetailsDatafromStore = getDataFromReduxStore();
    updateFieldIdsForSecondaryContact();
    const getUpdatedValueForLocationBasedOnTypeSelection = locationFilteredValueBasedOnType();
    const allUpdatedValue = [
      ...getUpdatedValueForLocationBasedOnTypeSelection,
      ...locData.location_data_state,
      ...personalData.personal_data_state,
      ...personalData.personal_data_secondary_state,
      ...requestDetailsDatafromStore,
      ...onlyOperatorIsPrimaryDetails,
      ...hasSecondarySelected,
      ...extraFieldsForPageInstrction.data,
    ];

    let finalInput = preparePageInstructions(allUpdatedValue);
    finalInput = [...reqDetailataFromReduxStore.requestForm.pageInstructions, ...finalInput];

    const data = {
      content: {},
      pageInstructions: finalInput,
    };

    request(appConstants.API_CASES + caseId.caseId, 'get')
      .then((res) => {
        const finalETagValue = res.data.content.pxSaveDateTime.replace(/[-:Z]/g, '') + ' GMT';

        setTimeout(() => {
          const config = {
            headers: {
              'if-match': finalETagValue,
            },
            withCredentials: true,
          };
          request(appConstants.API_CASES + caseId.caseId, 'put', { data, config })
            .then((res) => {
              setShowSpinnerApiIsInProgress(false);

              //if save is selected
              if (flag === true) {
                setSave(true);
              } else {
                //save and close is selected
                setSave(false);
              }
              setshowSuccess(true);
              setsuccessMessage(appConstants.SERVICE_REQUEST_SAVE_SUCCESS_MESSAGE);
            })
            .catch((error) => {
              setShowSpinnerApiIsInProgress(false);
              dispatch(setShowError({ hasError: true, error }));
            });
        }, 1000);
      })
      .catch((error) => {
        setShowSpinnerApiIsInProgress(false);
        dispatch(setShowError({ hasError: true, error }));
      });
  };
  const locationFilteredValueBasedOnType = () => {
    const isDefenseLocation = locData.location_data_state.some(
      (item) => item.fieldID === 'Type' && item.value === 'Base',
    );

    if (isDefenseLocation) {
      // if locationtype == base (defence location then we need to send other related field as blank )
      const filteredArr = locData.location_data_state.filter(
        (item) => item.fieldID !== 'Line1' || item.fieldID !== 'City' || item.fieldID !== 'Postcode',
      );
      const otherRelFieldsArr = [
        {
          reference: 'ServiceRequest.Location.Address.Line1',
          value: '',
          fieldID: 'Line1',
        },
        {
          reference: 'ServiceRequest.Location.Address.City',
          value: '',
          fieldID: 'City',
        },
        {
          reference: 'ServiceRequest.Location.Address.Postcode',
          value: '',
          fieldID: 'Postcode',
        },
      ];
      return [...filteredArr, ...otherRelFieldsArr];
    } else {
      // if locationtype == Other ( then we need to send defence location related field as blank )
      const filteredArr = locData.location_data_state.filter(
        (item) => item.fieldID !== 'FullAddress' || item.fieldID !== 'Name' || item.fieldID !== 'SiteOrWharf',
      );
      const defenceLocationRelFieldsArr = [
        {
          reference: 'ServiceRequest.Location.Address.FullAddress',
          value: '',
          fieldID: 'FullAddress',
        },
        {
          reference: 'ServiceRequest.Location.Name',
          value: '',
          fieldID: 'Name',
        },
        {
          reference: 'ServiceRequest.Location.Vessel.SiteOrWharf',
          value: '',
          fieldID: 'SiteOrWharf',
        },
      ];
      return [...filteredArr, ...defenceLocationRelFieldsArr];
    }
  };
  const handleUploadPopUp = () => {
    if (uploadedFile > 0) {
      setShowUploadModal(true);
    } else {
      handleCallBackUpdateForms();
    }
  };

  const handleCallBackUpdateForms = () => {
    // NOTE: Here we are sending all fields for pageinstruction of location and
    // personal details but for request details we will be sending only those which are updated by user

    setShowSpinnerApiIsInProgress(true);
    const onlyOperatorIsPrimaryDetails = otherData.other_data_state.filter(
      (item) => item.fieldID === 'OperatorIsPrimary',
    );
    const hasSecondarySelected = getDataForSeconadaryForPageInstruction();
    const requestDetailsDatafromStore = getDataFromReduxStore();
    updateFieldIdsForSecondaryContact();
    const getUpdatedValueForLocationBasedOnTypeSelection = locationFilteredValueBasedOnType();

    const allUpdatedValue = [
      ...getUpdatedValueForLocationBasedOnTypeSelection,
      ...personalData.personal_data_state,
      ...personalData.personal_data_secondary_state,
      ...requestDetailsDatafromStore,
      ...onlyOperatorIsPrimaryDetails,
      ...hasSecondarySelected,
      ...extraFieldsForPageInstrction.data,
    ];

    let finalInput = preparePageInstructions(allUpdatedValue);
    finalInput = [...reqDetailataFromReduxStore.requestForm.pageInstructions, ...finalInput];

    const data = {
      content: {},
      pageInstructions: finalInput,
    };
    const URL =
      appConstants.API_ASSIGNMENTS + nextAssignmentIdState.nextAssignmentId + '?actionID=' + actionIdIdState.actionId;
    request(URL, 'post', { data })
      .then((res) => {
        setShowSpinnerApiIsInProgress(false);
        navigate('/my-service-request/review-request/' + nextAssignmentIdState.nextAssignmentId, {
          state: [
            {
              nextAssignmentIdState,
              locData,
              personalData,
              otherData,
              requestInfo,
              stateDropdown,
              locationTypeDropdown,
              baseNameDropDown,
              entityDropdown,
              vesselDropdown,
              showSecondary,
              isMinorRequest,
              caseId,
            },
          ],
        });
      })
      .catch((error) => {
        setShowSpinnerApiIsInProgress(false);
        dispatch(setShowError({ hasError: true, error }));
      });
    window.scrollTo(0, 0); //scroll to top of review page once everything is done
  };
  const addSecondaryContactHandler = () => {
    if (showSecondary.isVisible) {
      if (personalData.personal_data_secondary_state.length > 0) {
        setPersonalData((prevState) => {
          const updatedSecondaryData = prevState.personal_data_secondary_state.map((item) => {
            return {
              ...item,
              value: '',
            };
          });

          return {
            ...prevState,
            personal_data_secondary_state: [...updatedSecondaryData],
          };
        });

        setEntityDropdown((prevState) => {
          return {
            ...prevState,

            selectedValueSecondary: '',
            selectedDescriptionSecondary: 'Select',
          };
        });
      }
    }
    setShowSecondary((prevState) => {
      return {
        isVisible: !showSecondary.isVisible,
      };
    });
  };
  useEffect(() => {
    ViewReqInNewState();
  }, []);

  const handleVesselCheckBox = (isChecked) => {
    setVesselDropdown((prevState) => {
      return {
        ...prevState,
        selectedValue: '',
        selectedDescription: '',
      };
    });
    if (!isChecked) {
      setLocData((prevState) => {
        return {
          ...prevState,
          location_data_state: prevState.location_data_state.map((item) => {
            if (item.fieldID === 'SiteOrWharf') {
              return {
                ...item,
                error: false,
                value: '',
                errorMessage: '',
              };
            } else {
              return {
                ...item,
              };
            }
          }),
        };
      });
    } else {
      setLocData((prevState) => {
        return {
          ...prevState,
          location_data_state: prevState.location_data_state.map((item) => {
            if (item.fieldID === 'SiteOrWharf') {
              return {
                ...item,
                error: true,
                value: '',
                errorMessage: changeNameHandlerForValidation(item.reference, item.fieldID) + ' is required.',
              };
            } else {
              return {
                ...item,
              };
            }
          }),
        };
      });
    }
  };
  const onSuccespopupClose = () => {
    setshowSuccess(false);
    if (showSave === false) {
      navigate('/my-service-request');
    }
  };
  const getValidationForDropdown = (isError) => {
    setIsDropdownSelected(isError);
  };

  const changeNameHandlerForValidation = (reference, fieldName) => {
    if (reference === 'ServiceRequest.Location.Type') {
      return 'Location type';
    } else if (reference === 'ServiceRequest.Location.Name') {
      return 'Defence Location';
    } else if (reference === 'ServiceRequest.Location.Address.FullAddress') {
      return 'Address';
    } else if (reference === 'ServiceRequest.Location.Address.Area') {
      return 'Impacted area';
    } else if (reference === 'ServiceRequest.Location.Description') {
      return 'Location Description';
    } else if (reference === 'ServiceRequest.Location.Address.Line1') {
      return 'Address';
    } else if (reference === 'ServiceRequest.PrimaryContact.Email(1).Address') {
      return 'Personal email address';
    } else if (reference === 'ServiceRequest.PrimaryContact.Phone(1).Number') {
      return 'Personal contact number';
    } else if (reference === 'ServiceRequest.Location.Vessel.SiteOrWharf') {
      return 'Vessel name';
    } else if (
      reference === 'ServiceRequest.PrimaryContact.Service' ||
      reference === 'ServiceRequest.Contacts(1).Service'
    ) {
      return "Primary contact's group or entity";
    } else if (reference === 'ServiceRequest.PrimaryContact.FullName') {
      return 'Full name';
    } else {
      return fieldName;
    }
  };

  return (
    <>
      <DeleteModal
        show={showDeleteModal}
        closeAction={() => setShowDeleteModal(false)}
        deleteAction={() => deleteSRHandler()}
      />

      {showUploadModal && (
        <UploadConfirmationSRModal
          handleUpdateForms={handleCallBackUpdateForms}
          show={showUploadModal}
          closeAction={() => setShowUploadModal(false)}
        />
      )}

      <div>
        <SuccessPopup show={showSuccess} message={successMessage} closeAction={() => onSuccespopupClose()} />
        <div className="newRequestBackground ">
          <Header
            {...content.header}
            mainHeading={isMinorRequest ? 'Minor maintenance request' : 'New service request'}
          />

          {loading.isLoading && (
            <Container>
              <h2 style={{ textAlign: 'center', margin: '20px' }}>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </h2>
            </Container>
          )}
          {!loading.isLoading && (
            <SubContainer>
              <RequestDetail
                isminorReqFlag={isMinorRequest}
                handleUpdate={handleRequestDetailsUpdateForCommonFields}
                caseID={nextAssignmentIdState.nextAssignmentId.slice(
                  nextAssignmentIdState.nextAssignmentId.indexOf('S-'),
                  nextAssignmentIdState.nextAssignmentId.indexOf('!'),
                )}
                caseIDOriginal={caseId.caseId}
                nextAssignmentId={nextAssignmentIdState.nextAssignmentId}
                actionID={actionIdIdState.actionId}
                fromview={true}
                workType={reqDetailwrkTypeSubType.workType}
                workSubType={reqDetailwrkTypeSubType.workSubType}
                description={reqDetailwrkTypeSubType.description}
                dropdownError={getValidationForDropdown}
              />

              {/* LOCATION DETAILS COMPONENT */}
              <Container className="py-3 pt-lg-4">
                <h2>{LocationDetailProps.mainHeading}</h2>
                <h4 className="fw-light">
                  {LocationDetailProps.subHeading}{' '}
                  <button
                    title="Tooltip"
                    className="border-0 bg-transparent"
                    ref={target}
                    onClick={() => setShow(!show)}
                  >
                    <InfoCircle size={15} />
                  </button>
                </h4>

                <Overlay target={target.current} show={show} placement="right">
                  {(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      These details can be edited, however if pre-filled details are not correct you may need to visit
                      the Profile tab and update your home base or working location.
                    </Tooltip>
                  )}
                </Overlay>
                <div className="fieldsContainer">
                  {locData.location_data_state.map(
                    (item, index) =>
                      item.error && (
                        <div className="error-msg" key={index + 'error-msg-loc'}>
                          <span className="align-center-error">
                            <Image alt="error" className="icon-size-small" src={alertImage} />
                          </span>
                          <span className="align-center-error-message">{item.errorMessage}</span>
                        </div>
                      ),
                  )}
                </div>
                <div className="fieldsContainer">
                  {locData.location_data_state.length > 0 &&
                    locData.location_data_state.map((item, index) => (
                      <Location
                        key={index + 'loc'}
                        check={locData.location_data_state}
                        reqInfo={requestInfo}
                        locationDetail={item}
                        stateValues={stateDropdown}
                        VesselValues={vesselDropdown}
                        locationTypeValues={locationTypeDropdown}
                        baseNameValues={baseNameDropDown}
                        onLocationInfoChange={handleLocationDataUpdate}
                        onStateLocationTypeDroppDownChange={handleStateLocationTypeDropdownUpdate}
                        onAddVesselSelected={handleVesselCheckBox}
                        onBaseNameDropDownChange={handleBaseNameDropdownUpdate}
                        defenceLocationText={handleDefenceLocationText}
                        onVesselDropDownChange={handleVesselDropDownUpdate}
                        isReadonlyLocation={false}
                      />
                    ))}
                </div>
              </Container>

              {/* PERSONAL DETAILS COMPONENT */}
              <Container className="pt-4 pt-lg-6">
                <h2>{PersonalDetailProps.mainHeading}</h2>

                <Container style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                  {otherData.other_data_state.length > 0 &&
                    otherData.other_data_state.map((item, index) => (
                      <Other
                        key={index + 'other'}
                        otherDetail={item}
                        onOtherInfoChange={handleOtherDetailsUpdate}
                        isReadonlyOther={false}
                      />
                    ))}
                </Container>
                <h3 className="fw-light">{PersonalDetailProps.subHeading}</h3>
                <div className="fieldsContainer">
                  {personalData.personal_data_state.map(
                    (item, index) =>
                      item.error && (
                        <div className="error-msg" key={index + 'error-msg-personal'}>
                          <span className="align-center-error">
                            <Image alt="error" className="icon-size-small" src={alertImage} />
                          </span>
                          <span className="align-center-error-message">{item.errorMessage}</span>
                        </div>
                      ),
                  )}
                </div>
                <div className="fieldsContainer">
                  {personalData.personal_data_state.length > 0 &&
                    otherData.other_data_state[3].value === 'false' &&
                    personalData.personal_data_state.map((item, index) => (
                      <>
                        <PersonalDetailsPrimary
                          nopersonal="true"
                          key={index + 'false'}
                          isNoDisableFlag={true}
                          entityValues={entityDropdown}
                          onEntityChange={handleEntityDropdownUpdate}
                          personalDetail={item}
                          onPersonalInfoChange={handlePersonalDetailsUpdate}
                          isReadonlyPersonalDetails={true}
                        />
                      </>
                    ))}
                </div>
                <div className="fieldsContainer">
                  {otherData.isPrimarySelected === 'true' &&
                    personalData.personal_data_state.map((item, index) => (
                      <>
                        <PersonalDetailsPrimary
                          key={index + 'true'}
                          nopersonal="false"
                          entityValues={entityDropdown}
                          onEntityChange={handleEntityDropdownUpdate}
                          personalDetail={item}
                          isNoDisableFlag={otherData.isPrimarySelected === 'false'}
                          onPersonalInfoChange={handlePersonalDetailsUpdate}
                          isReadonlyPersonalDetails={true}
                        />
                      </>
                    ))}
                </div>
              </Container>
              {/* SECONDARY CARD SELECTOR COMPONENT */}
              <Container className="pt-6">
                <div className="secondaryContactCard bg-defencelightblue">
                  <Container fluid className="p-5">
                    <h3 className="pb-2">{SecondaryContactCardProps.mainHeading}</h3>
                    <h3 className="fw-light">{SecondaryContactCardProps.subHeading}</h3>
                    <Form.Check
                      type="switch"
                      name="addSecondary"
                      checked={showSecondary.isVisible}
                      onChange={addSecondaryContactHandler}
                      disabled={otherData.isPrimarySelected === 'false'}
                      className="secondarySwitch"
                      label="Add contact details"
                    />
                  </Container>
                </div>
              </Container>
              {/* SECONDARY PERSONAL DETAILS COMPONENT */}
              <Container className="pt-4 pt-lg-6">
                {showSecondary.isVisible && otherData.isPrimarySelected === 'true' && (
                  <>
                    <h2>Alternate point of contact</h2>
                    <PersonalDetailsSecondary
                      allDetailsOnSecondary={personalData.personal_data_secondary_state}
                      onIsInvalidsecondaryForm={(flag) => setsecondaryContactformInvalid(flag)}
                      entityValuesSecondary={entityDropdown}
                      onEntityChangeSecondary={handleEntityDropdownUpdateSecondary}
                      onPersonalSeconadryInfoChange={handlePersonalSecondaryDetailsUpdate}
                      isReadonlyPersonalSecondaryDetails={false}
                    />
                  </>
                )}
              </Container>
              <Container className="pb-4 pb-lg-6">
                {otherData.isPrimarySelected === 'false' && (
                  <>
                    <h2>Alternate point of contact</h2>

                    <PersonalDetailsSecondary
                      entityValuesSecondary={entityDropdown}
                      onEntityChangeSecondary={handleEntityDropdownUpdateSecondary}
                      onIsInvalidsecondaryForm={(flag) => setsecondaryContactformInvalid(flag)}
                      allDetailsOnSecondary={personalData.personal_data_secondary_state}
                      onPersonalSeconadryInfoChange={handlePersonalSecondaryDetailsUpdate}
                      isReadonlyPersonalSecondaryDetails={true}
                    />
                  </>
                )}
              </Container>
              {showSpinnerApiIsInProgress && <Spinner />}
              <Row className="buttonContainer">
                <Col
                  md
                  className="d-flex justify-content-start pt-xs-2 pt-2 pb-6 col-md-12 col-lg-8 order-2 order-lg-1"
                >
                  <Button variant="standard" className="mobileButton" onClick={() => saveServiceRequestHandler(false)}>
                    Save & close
                  </Button>
                  <Container className="ps-3">
                    <Button variant="standard" className="mobileButton" onClick={() => saveServiceRequestHandler(true)}>
                      Save
                    </Button>
                  </Container>
                </Col>
                <Col md className="d-xs-inline d-lg-flex pt-2 justify-content-end order-1 order-lg-2">
                  <Row>
                    <Col
                      xs={12}
                      className="col-xs-12 col-sm-12 col md-12 col-lg-4 pt-2 pb-lg-0 pe-xs-0 pe-lg-3 d-flex justify-content-end order-2 order-lg-1"
                    >
                      <Button variant="secondary" className="mobileButton" onClick={() => setShowDeleteModal(true)}>
                        Delete
                      </Button>
                    </Col>
                    <Col
                      xs={12}
                      className="col-xs-12 col-sm-12 col-md-12 col-lg-8 pt-lg-2 d-flex justify-content-end order-1 order-lg-2"
                    >
                      <Button
                        disabled={isFormInvalid()}
                        variant="primary"
                        className="mobileButton"
                        onClick={handleUploadPopUp}
                      >
                        Review request
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </SubContainer>
          )}
        </div>
      </div>
    </>
  );
};

export default EditServiceRequestCreate;
