import * as React from 'react';
import { SVGProps } from 'react';
const Close = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_2311_9717" maskUnits="userSpaceOnUse" x="2" y="2" width="22" height="22">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3157 9.68355C15.9859 9.35377 15.4518 9.35377 15.122 9.68355L12.9998 11.8058L10.8776 9.68355C10.5478 9.35377 10.0138 9.35377 9.68399 9.68355C9.35421 10.0133 9.35421 10.548 9.68399 10.8772L11.8062 12.9994L9.68399 15.1222C9.35421 15.4514 9.35421 15.986 9.68399 16.3158C9.84888 16.4807 10.0644 16.5629 10.2805 16.5629C10.4966 16.5629 10.7127 16.4807 10.8776 16.3158L12.9998 14.193L15.122 16.3158C15.2869 16.4807 15.503 16.5629 15.7191 16.5629C15.9352 16.5629 16.1508 16.4807 16.3157 16.3158C16.6455 15.986 16.6455 15.4514 16.3157 15.1222L14.1935 12.9994L16.3157 10.8772C16.6455 10.548 16.6455 10.0133 16.3157 9.68355ZM12.9998 22.145C7.95741 22.145 3.85482 18.0424 3.85482 13C3.85482 7.95697 7.95741 3.85494 12.9998 3.85494C18.0428 3.85494 22.1449 7.95697 22.1449 13C22.1449 18.0424 18.0428 22.145 12.9998 22.145ZM12.9998 2.16663C7.02659 2.16663 2.1665 7.02615 2.1665 13C2.1665 18.9732 7.02659 23.8333 12.9998 23.8333C18.9736 23.8333 23.8332 18.9732 23.8332 13C23.8332 7.02615 18.9736 2.16663 12.9998 2.16663Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2311_9717)">
      <rect width="26" height="26" fill="#1F2827" />
    </g>
  </svg>
);
export default Close;
